import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import axios from 'axios';

// const SuggestionsInput = ({ Address,  setAddress, setStreet, setHouse, setArea, setDistrict, City }) => {
const SuggestionsInput = ({ Address,  setAddress, setDistrict, City, addressMap }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {

    if (inputValue.length > 2) {
      fetchSuggestions(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue]);

  useEffect( () => {
    async function  dadata () {
      if(addressMap) {
        const response = await fetchSuggestions(addressMap)
        setAddress(response[0]?.value)
        setDistrict(response[0]?.data.city_area)
      }
      return true
    }
    dadata()
  }, [addressMap]);

  const fetchSuggestions = async (query) => {
    const token = process.env.REACT_APP_TOKEN;
    try {
      const response = await axios.post(process.env.REACT_APP_URL_DADATA, {
        query: `${City} ${query}`
      }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
        }
      });
      const arrayOptions = response.data.suggestions.map(el => {
        return {data: el.data, value: el.value}
      })
      setOptions(arrayOptions)
      return arrayOptions
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <>
    <Autocomplete
      id="google-map-demo"
      sx={{  width: 800, display: 'flex', alignItems: 'center' }}
      // getOptionLabel={(option) => (typeof option === 'string' ? option : option.value)}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.value)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={Address}
      noOptionsText="Введите больше 2х символов"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        if ( newValue ) {
          setAddress(newValue.value);
          setDistrict(newValue.data.city_area);
          // setArea(newValue.data.city_area);
          // setStreet(newValue.data.street);
          // setHouse( newValue.data.block_type ? `${newValue.data.house} ${newValue.data.block_type} ${newValue.data.block}` : newValue.data.house);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        setAddress(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Введите адрес" fullWidth 
        sx={{m: 1}} maxRows={4} multiline
        />
      )}
      renderOption={(props, option) => {
        return (
          <>
            <li {...props} key={props.key}>
              <Grid container alignItems="center">
                <Grid item sx={{ width: '50vw', wordWrap: 'break-word' }}>
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    {props.key.substring(0, 0)}
                  </Box>
                  <Box component="span">
                    {props.key.substring(0, 10)}
                  </Box>
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    {props.key.substring(10)}
                  </Box>
                </Grid>
              </Grid>
            </li>
          </>
        );
      }}
    />
    </>
  );
};

export default SuggestionsInput;
