import React, { useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import axios from 'axios';
import { formatter } from '../../utils/phoneNumber.js';
import ListUser from '../ListUser/ListUser';
import Header from '../Header/Header';
import ButtonError from '../ButtonError/ButtonError';
import { checkPassword } from '../../utils/checkPassword.js';
import {statusArray} from "../../utils/status";
import ModalMessage from "../ModalMessage/ModalMessage";
import InstructionUploadButton from "../InstructionUploadButton/InstructionUploadButton";

const url = process.env.REACT_APP_SERVER_URL;
const path = process.env.REACT_APP_PATH;

const FormUser = () => {

    let navigate = useNavigate();
    const time = 2000
    const [Phone, setPhone] = useState('');
    const [Name, setName] = useState('');
    const [Message, setMessage] = useState('');
    const [Color, setColor] = useState('');
    const [Login, setLogin] = useState('');
    const [Status, setStatus] = useState('');
    const [Organization, setOrganization] = useState('');
    const [listOrganization, setListOrganization] = useState([]);
    const [Password, setPassword] = useState('');
    const [UsersList, setUsersList] = useState([]);

    useEffect (() => {
        if(localStorage?.expire) {
            const nowDate = Math.floor(Date.now() / 1000);
            if(localStorage?.expire > nowDate){
                axios.post(`${url}/token/token`, localStorage, {
                    headers: {
                        'jwt-token': localStorage?.access,
                    }
                })
                    .then((response) => {
                        if (response.status === 210) {
                            navigate(`${path}/changePassword`, { replace: true });
                        } else if(response.status === 200) {
                            if(Number(localStorage?.status) === 1 || Number(localStorage?.status) === 2 || Number(localStorage?.status) === 3) {
                                updateListUser()
                            } else {
                                navigate(`${path}/application`, { replace: true });
                            }
                        } else {
                            navigate(`${path}/login`, { replace: true });
                            // console.log('токена нет на сервере')
                        }
                    })
                    .catch((error) => {
                        // console.log(error)
                    })
            } else {
                navigate(`${path}/login`, { replace: true });
                // console.log('истекло время для токена')
            }
        } else {
            navigate(`${path}/login`, { replace: true });
        }
        axios.get(`${url}/organization`, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
            .then((response) => {
                if ( response.status === 201) {
                    setListOrganization(response.data.organizations)
                } else if(response.status === 210) {
                    navigate(`${path}/changePassword`, { replace: true });
                } else {
                    setMessage('Произошла ошибка')
                    setTimeout(()=> {
                        setMessage('')
                    }, time)
                }
            })
            .catch((error) => {
                setMessage(error.err)
                setTimeout(()=> {
                    setMessage('')
                }, time)
            })
    }, [])

    const updateListUser = () => {
        axios.get(`${url}/accounts/listUser`, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
            .then((response) => {
                if ( response.status === 201) {
                    setUsersList(response.data)
                } else if (response.status === 210) {
                    navigate(`${path}/changePassword`, { replace: true });
                } else {
                    setMessage('Произошла ошибка при выгрузке пользователей, попробуйте позже')
                    setTimeout(()=> {
                        setMessage('')
                    }, time)
                }
            })
            .catch((error) => {
                setMessage('Произошла ошибка при выгрузке пользователей, попробуйте позже')
                setTimeout(()=> {
                    setMessage('')
                }, time)
            })
    }

    const getAvailableStatuses = () => {
        const currentStatus = Number(localStorage?.status);
        if (currentStatus === 1) {
            return statusArray.filter(([key]) => key === '0');
        } else if (currentStatus === 2) {
            return statusArray.filter(([key]) => key === '0' || key === '1');
        } else if (currentStatus === 3) {
            return statusArray;
        } else {
            return [];
        }
    };

    const getFilteredOrganizations = () => {
        const currentStatus = Number(localStorage?.status);
        if (currentStatus < 2) {
            // Если статус меньше 2, отображаем только организацию из localStorage
            const organizationFromStorage = localStorage?.organization;
            return listOrganization.filter(org => org.title === organizationFromStorage);
        } else {
            // В противном случае отображаем все организации
            return listOrganization;
        }
    };


    const applicationNavigate = () => {
        navigate(`${path}/application`, { replace: true });
    }

    const createUser = (event) => {
        event.preventDefault();
        if ( !Phone || !Name || !Password || !Login || !Status || !Organization) {
            setColor('red')
            setMessage('Заполните все данные')
            setTimeout(()=> {
                setMessage('')
            }, time)
        } else {
            const checkPass = checkPassword(Password);
            if(!checkPass) {
                setColor('red')
                setMessage('Пароль не подходит под условия')
                setTimeout(() => {
                    setMessage('')
                }, time)
            }
            const checkNumber = formatter(Phone, 'ru')
            if (!checkNumber) {
                setColor('red')
                setMessage('Неверный формат или номер телефона')
                setTimeout(() => {
                    setMessage('')
                }, time)
            } else {
                axios.post(`${url}/accounts/createUser`, {Phone: checkNumber, Name, Login, Password, Organization, Status}, {
                    headers: {
                        'jwt-token': localStorage?.access,
                    }
                })
                    .then((response) => {
                        if(response.status === 201) {
                            setColor('green')
                            setMessage(response.data.message)
                            setTimeout(() => {
                                setMessage('')
                            }, time)
                            setPassword('')
                            setLogin('')
                            setName('')
                            setPhone('')
                            updateListUser()
                        }  else if (response.status === 210) {
                            navigate(`${path}/changePassword`, { replace: true });
                        } else {
                            setColor('orange')
                            setMessage(response.data.message)
                            setTimeout(() => {
                                setMessage('')
                            }, time)
                        }
                    })
                    .catch((err) => {
                        // console.log(err)
                        setColor('red')
                        setMessage('Произошла ошибка, попробуйте позже')
                        setTimeout(() => {
                            setMessage('')
                        }, time)
                    })
            }
        }
    }

    return (
        <>
            <div style={{display: "flex", justifyContent: "flex-end", flexDirection: "column"}}>
                <Header applicationNavigate={applicationNavigate}/>
            </div>

            <div style={{margin: '1vw', marginTop: "20px"}}>
                <form onSubmit={createUser} id='formApplication' autoComplete="off"
                      style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px'}}>
                    <TextField onChange={(e) => setLogin(e.target.value)} name="formUserLogin" value={Login}
                               sx={{margin: '10px', width: "100%", textAlign: 'left'}}
                               label="Введите логин" className="outlined-size-normal" placeholder=""/>

                    <TextField onChange={(e) => setPassword(e.target.value)} name="formUserPassword" value={Password}
                               sx={{margin: '10px', width: "100%", textAlign: 'left'}}
                               label="Введите пароль" className="outlined-size-normal" placeholder=""/>

                    <TextField onChange={(e) => setName(e.target.value)} name="Name" value={Name}
                               sx={{margin: '10px', width: "100%", textAlign: 'left'}}
                               label="Введите наименование" className="outlined-size-normal" placeholder=""/>

                    <TextField onChange={(e) => setPhone(e.target.value)} name="Phone" value={Phone}
                               sx={{margin: '10px', width: "100%", textAlign: 'left'}}
                               label="Введите номер телефона" className="outlined-size-normal" placeholder=""/>

                    <FormControl sx={{ margin: '10px', width: "100%" }}>
                        <InputLabel>Выберите статус</InputLabel>
                        <Select
                            value={Status}
                            onChange={(e) => setStatus(e.target.value)}
                            label="Выберите статус"
                            sx={{ textAlign: 'left' }}
                        >
                            {getAvailableStatuses().map(([key, value]) => (
                                <MenuItem key={key} value={key}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{margin: '10px', width: "100%"}}>
                        <InputLabel>Выберите организацию</InputLabel>
                        <Select
                            value={Organization}
                            onChange={(e) => setOrganization(e.target.value)}
                            label="Выберите организацию"
                            sx={{textAlign: 'left'}}
                        >
                            {getFilteredOrganizations().map((organization) => (
                                <MenuItem key={organization.id} value={organization.id}>
                                    {organization.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div style={{ gridColumn: '1 / span 2', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Button type="submit" variant="contained" color="primary">
                            Создать пользователя
                        </Button>
                    </div>
                </form>
            </div>

            <div style={{margin: '1vw', marginTop: "20px"}}>
                <ModalMessage message={Message} color={Color}/>
                <ListUser UsersList={UsersList} updateListUser={updateListUser} setUsersList={setUsersList}/>
            </div>
        </>
    );
};

export default FormUser;