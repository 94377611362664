import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ListApplication.css'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Button,
    Typography
} from '@mui/material';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import InfoModal from '../InfoModal/InfoModal';
import DetailsModal from '../DetailsModal/DetailsModal';
import SeachApplication from '../SeachApplication/SeachApplication';
import * as XLSX from 'xlsx';
import {defineStatus} from '../../utils/defineStatus.mjs';
import {getDescription} from '../../utils/getDescription';
import Header from "../Header/Header";
import ButtonError from "../ButtonError/ButtonError";
import InstructionUploadButton from "../InstructionUploadButton/InstructionUploadButton";

const path = process.env.REACT_APP_PATH;
const url = process.env.REACT_APP_SERVER_URL;

const ListApplication = ( ) => {
    let navigate = useNavigate();

    const [allApplication, setAllApplication] = useState([0]);
    const [message, setMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenInfo, setIsModalOpenInfo] = useState(false);
    const [isModalOpenDetails, setIsModalOpenDetails] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedDate, setSelectedDate] = useState({});    
    const [reasonCancellation, setReasonCancellation] = useState('');
    const [errorCancel, setErrorCancel] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [organization, setOrganization] = useState('');
    const currentDate = new Date();
    const [startDate, setStartDate] = useState(currentDate.toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(currentDate.toISOString().split('T')[0]);
    const [Reason, setReason] = useState([]);
    const [Status, setStatus] = useState([]);
    const [dispetcher, setDispetcher] = useState([]);
    const [searchFilter, setSearchFilter] = useState(false)
    const [countApplication, setCountApplication] = useState('');
    const [loadingXlsx, setLoadingXlsx] = useState(false);
    const perPage = 10;

    const csvData = (array) => {
        return array.map(item => {
            const dateObj = new Date(item.dateTime);
            const formattedDate = dateObj.toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                timeZone: 'Europe/Moscow'
            });
            dateObj.setHours(dateObj.getHours() + 3);
            const formattedTime = dateObj.toLocaleTimeString('ru-RU', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            });
            const data = {
                'Номер заявки': item.regNumber || 'пока нет номера заявки',
                'Состояние': defineStatus(item.notes?.status),
                'Причина обращения': getDescription(item.emergencyType),
                'Уточнение': item?.emergencyInfo?.text,
                'Адрес': item?.location?.result || '',
                'Примечание': item?.location?.addInfo,
                'Дата': formattedDate,
                'Время': formattedTime,
            }
            if (Number(localStorage?.status)) {
                data['Огранизация'] = item?.legalEntityType
                data['Диспетчер'] = item?.contactName
            }
            return data;
        });
    } 

    const exportToXLSX = () => {
        setLoadingXlsx(true)
        const data = {
            organization, 
            dispetcher, 
            status: Status,
            reason: Reason, 
        }
        if(searchFilter) {
            data.startDate = startDate
            data.endDate = endDate
        }
        axios.post(`${url}/application/xlsx`, data,
        {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
        .then((response) => {
            const ws = XLSX.utils.json_to_sheet(csvData(response.data));
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'аварийные_заявки.xlsx');
            setLoadingXlsx(false)
        })
        .catch((error) => {
            setMessage('Произошла ошибка во время выгрузки данных в файл, попробуйте позже');
            setTimeout(()=> {
                setMessage('')
            }, 5000)
            setLoadingXlsx(false)
        })
    };

    const handleOpenModalDetails = (item, formattedDate) => {
        setSelectedItem(item);
        setSelectedDate(formattedDate);
        setIsModalOpenDetails(true);
    };
  
    const handleCloseModalDetails = () => {
        setSelectedItem(null);
        setIsModalOpenDetails(false);
    };

    useEffect (() => {
        if(localStorage?.expire) {
            const nowDate = Math.floor(Date.now() / 1000);
            if(localStorage?.expire > nowDate){
                axios.post(`${url}/token/token`, localStorage, {
                    headers: {
                        'jwt-token': localStorage?.access,
                    }
                })
                    .then((response) => {
                        if (response.status === 210) {
                            navigate(`${path}/changePassword`, { replace: true });
                        } else if(response.status === 200) {
                            fetchApplications()
                        } else {
                            navigate(`${path}/login`, { replace: true });
                            // console.log('токена нет на сервере')
                        }
                    })
                    .catch((error) => {
                        // console.log(error)
                    })
            } else {
                navigate(`${path}/login`, { replace: true });
                // console.log('истекло время для токена')
            }
        } else {
            navigate(`${path}/login`, { replace: true });
            // console.log('нет токена на клиенте')
        }
    }, [])
 
    useEffect(() => {
        if( searchFilter) {
            fetchApplications(true)
        } else {
            fetchApplications()
        }
    }, [currentPage])

    const handleOpenModal = (item) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    };
    
    const handleCloseModal = () => {
        setSelectedItem(null);
        setIsModalOpen(false);
    };

    const fetchApplications = async ( params = false ) => {
    try {
        if(!organization) {
            setOrganization(localStorage?.organizationId)
        }
        if(params) {
            setSearchFilter(true)
            const response = await axios.post(`${url}/application/listSearch`, {
                organization, 
                dispetcher, 
                status: Status, 
                startDate, 
                endDate,
                reason: Reason,
                limit: perPage, // Количество записей на странице
                offset: (currentPage - 1) * perPage // Рассчитываем смещение
            },
            {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            });
            if (response.status === 201) {
                setAllApplication(response.data.applications);
                setCountApplication(response.data.count)
            } else if (response.status === 210) {
                navigate(`${path}/changePassword`, { replace: true });
            } else {
                setMessage('Произошла ошибка, попробуйте позже');
                setTimeout(()=> {
                    setMessage('')
                }, 5000)
            }
        } else {
            const response = await axios.post(`${url}/application/list`, {
                    limit: perPage, // Количество записей на странице
                    offset: (currentPage - 1) * perPage // Рассчитываем смещение
            },
            {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            });
            if (response.status === 201) {
                setAllApplication(response.data.applications);
                setCountApplication(response.data.count)
            } else if (response.status === 210) {
                navigate(`${path}/changePassword`, { replace: true });
            } else {
                setMessage('Произошла ошибка, попробуйте позже');
                setTimeout(()=> {
                    setMessage('')
                }, 5000)
            }
        }
    } catch (error) {
        setMessage('Произошла ошибка, попробуйте позже');
        setTimeout(()=> {
            setMessage('')
        }, 5000)
    }
    };

    const handleConfirmAction = (item) => {
        if( reasonCancellation.length > 3) {
            axios.post(`${url}/application/cancel`, {reasonCancellation, item}, {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
            .then((response) => { 
                if( response.status === 201) {
                    fetchApplications()
                } else if (response.status === 210) {
                    navigate(`${path}/changePassword`, { replace: true });
                } else {
                    if(!item.regNumber) {
                        setMessage('Нельзя отменить не зарегестрированную заявку')
                    } else {
                        setMessage(response.data.message)
                    }
                    setTimeout(()=> {
                        setMessage('')
                    }, 5000)
                }
            })
            .catch((error) => {
                setMessage(error.response.data.err)
                setTimeout(()=> {
                    setMessage('')
                }, 5000)
            })
            setReasonCancellation('')
            handleCloseModal();
        } else {
            setErrorCancel('Введите причину отмены')
            setTimeout(()=> {
                setErrorCancel('')
            }, 5000)
        }
    };

    const handleOpenModalInfo = (item) => {
        setSelectedItem(item);
        setIsModalOpenInfo(true);
    };
  
    const handleCloseModalInfo = () => {
        setSelectedItem(null);
        setIsModalOpenInfo(false);
    };

    const handleSaveInfo = (info, application) => {
        axios.post(`${url}/application/addInfo`, {info, application}, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
        .then((response) => { 
            if (response.status === 210) {
                navigate(`${path}/changePassword`, { replace: true });
            } else {
                fetchApplications()
            }
        })
        .catch((error) => {
            setMessage(error.err)
            setTimeout(()=> {
                setMessage('')
            }, 5000)
        })
    };
    
    const buttonPrevious = () => {
        setCurrentPage(currentPage - 1)
        setAllApplication([0])
    }

    const buttonNext = () => {
        setCurrentPage(currentPage + 1)
        setAllApplication([0])
    }

    return (
        <div>
            <div style={{display: "flex", justifyContent: "flex-end", flexDirection: "column"}}>
                <Header />
            </div>
            <div style={{margin: '1vw', marginTop: "20px"}}>

                {localStorage.userName && (
                    <Typography variant={'h5'}>{localStorage.userName}</Typography>
                )}
            </div>
            <SeachApplication
                organization={organization}
                setOrganization={setOrganization}
                setStartDate={setStartDate}
                startDate={startDate}
                setEndDate={setEndDate}
                endDate={endDate}
                fetchApplications={fetchApplications}
                Reason={Reason}
                setReason={setReason}
                Status={Status}
                setStatus={setStatus}
                dispetcher={dispetcher}
                setDispetcher={setDispetcher}
                setCurrentPage={setCurrentPage}
                setAllApplication={setAllApplication}
                setSearchFilter={setSearchFilter}
                exportToXLSX={exportToXLSX}
            />
            {loadingXlsx ? (<h5>Подождите, файл формируется</h5>) : (<></>)}
            <Box p={2}>
                {message && (
                    <h3 style={{color: 'red'}}>{message}</h3>
                )}
                {Number(localStorage?.status) >= 2 &&
                    <h5>Найдено завок: {countApplication}</h5>
                }
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Номер заявки</TableCell>
                                <TableCell>Статус</TableCell>
                                <TableCell>Причина обращения</TableCell>
                                <TableCell>Адрес</TableCell>
                                {Number(localStorage?.status) >= 2 ? <TableCell>Организация</TableCell> : <></>}
                                {Number(localStorage?.status) ? <TableCell>Диспетчер</TableCell> : <></>}
                                <TableCell>Дата</TableCell>
                                <TableCell>Время</TableCell>
                                <TableCell>Направить дополнительную информацию</TableCell>
                                <TableCell>Подробности по заявке</TableCell>
                                <TableCell>Отменить</TableCell>
                                <TableCell>Причина отмены</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allApplication[0] !== 0 ? (
                                <>
                                    {allApplication.map((item, index) => {
                                        const type = getDescription(item.emergencyType);
                                        const dateObj = new Date(item.dateTime);
                                        const cancel = item.notes?.cancel?.reasonCancellation;
                                        const status = defineStatus(item.notes?.status)
                                        const defaultStatus = item.notes?.status
                                        const formattedDate = dateObj.toLocaleDateString('ru-RU', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            timeZone: 'Europe/Moscow'
                                        });

                                        dateObj.setHours(dateObj.getHours() + 3);

                                        const formattedTime = dateObj.toLocaleTimeString('ru-RU', {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        });
                                        return (
                                            <TableRow key={item.id}>
                                                <TableCell>{item.regNumber || 'пока нет номера зявки'}</TableCell>
                                                <TableCell>{status}</TableCell>
                                                <TableCell>{type}</TableCell>
                                                <TableCell>{item?.location?.result}</TableCell>
                                                {Number(localStorage?.status) >= 2 ?
                                                    <TableCell>{item?.legalEntityType}</TableCell> : <></>}
                                                {Number(localStorage?.status) ?
                                                    <TableCell>{item?.contactName}</TableCell> : <></>}
                                                <TableCell>{formattedDate}</TableCell>
                                                <TableCell>{formattedTime}</TableCell>
                                                <TableCell>
                                                    {defaultStatus === 'closed' || defaultStatus === 'canceled' || defaultStatus === "canceledOk" ? (
                                                        <Button type="submit" disabled={true} style={{
                                                            opacity: '0.5',
                                                            cursor: 'not-allowed',
                                                            backgroundColor: '#f0f0f0',
                                                            color: '#c0c0c0',
                                                        }}>
                                                            Направить информацию
                                                        </Button>
                                                    ) : (
                                                        <Button type="submit" onClick={() => handleOpenModalInfo(item)}>
                                                            Направить информацию
                                                        </Button>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <Button type="submit"
                                                            onClick={() => handleOpenModalDetails(item, formattedDate, formattedTime)}>
                                                        Подробности
                                                    </Button>
                                                </TableCell>
                                                <TableCell>
                                                    {defaultStatus === 'closed' || defaultStatus === 'canceled' || defaultStatus === "canceledOk" ? (
                                                        <Button type="submit" disabled={true} style={{
                                                            opacity: '0.5',
                                                            cursor: 'not-allowed',
                                                            backgroundColor: '#f0f0f0',
                                                            color: '#c0c0c0',
                                                        }}>
                                                            Отменить
                                                        </Button>
                                                    ) : (
                                                        <Button type="submit" onClick={() => handleOpenModal(item)}>
                                                            Отменить
                                                        </Button>
                                                    )}
                                                </TableCell>

                                                <TableCell>{cancel}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    {isModalOpenDetails &&
                                        <DetailsModal
                                            key={selectedItem?.callID}
                                            isOpen={isModalOpenDetails}
                                            handleClose={handleCloseModalDetails}
                                            item={selectedItem}
                                            formattedDate={selectedDate}
                                        />
                                    }
                                    {isModalOpenInfo &&
                                        <InfoModal
                                            isOpenInfo={isModalOpenInfo}
                                            handleCloseInfo={handleCloseModalInfo}
                                            handleSaveInfo={handleSaveInfo}
                                            item={selectedItem}
                                        />
                                    }
                                    {isModalOpen &&
                                        <ConfirmationModal
                                            isOpen={isModalOpen}
                                            handleClose={handleCloseModal}
                                            handleConfirm={() => handleConfirmAction(selectedItem)}
                                            item={selectedItem}
                                            setReasonCancellation={setReasonCancellation}
                                            reasonCancellation={reasonCancellation}
                                            errorCancel={errorCancel}
                                        />
                                    }
                                </>
                            ) : (
                                <>
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div>
                    {allApplication[0] === 0 ? (
                        <>Идет загрузка</>
                    ) : (
                        allApplication.length === 0 ? (<>Нет заявок</>) : (<></>)
                    )}
                </div>
                <div style={{display: 'flex', justifyContent: "center"}}>
                    <Button onClick={buttonPrevious} disabled={currentPage === 1}>
                        Назад
                    </Button>
                    <h5>{currentPage}</h5>
                    <Button onClick={buttonNext} disabled={allApplication.length < perPage}>
                        Вперед
                    </Button>
                </div>
            </Box>
        </div>
    );
};

export default ListApplication;