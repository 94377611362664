import React, { useState, useEffect } from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

const YandexMap = ({ onMarkerSet, addressMap, setAddressMap }) => {
    const [coordinates, setCoordinates] = useState(null);
    const [ymapsInstance, setYmapsInstance] = useState(null);

    useEffect(() => {
        const loadYmaps = () => {
            if (!window.ymaps) {
                const script = document.createElement('script');
                script.src = `https://api-maps.yandex.ru/2.1/?apikey=${process.env.REACT_APP_YMAP_API_KEY}&lang=ru_RU`;
                script.type = 'text/javascript';
                script.onload = () => {
                    setYmapsInstance(window.ymaps);
                };
                script.onerror = () => console.error('Ошибка загрузки скрипта Яндекс карт');
                document.head.appendChild(script);

                return () => {
                    document.head.removeChild(script);
                };
            } else {
                setYmapsInstance(window.ymaps);
            }
        };

        loadYmaps();
    }, []);

    const getaddressMap = (coords) => {
        if (ymapsInstance) {
            ymapsInstance.geocode(coords).then((res) => {
                const firstGeoObject = res?.geoObjects?.get(0);
                const thoroughfare = firstGeoObject?.getThoroughfare() || ''; // Улица
                const premiseNumber = firstGeoObject?.getPremiseNumber() || ''; // Номер дома
                const fulladdressMap = `${thoroughfare}, ${premiseNumber}`.trim();

                if (fulladdressMap) {
                    setAddressMap(fulladdressMap);
                } else {
                    const foundaddressMap = firstGeoObject?.getaddressMapLine();
                    setAddressMap(foundaddressMap);
                }
            }).catch((err) => {
                console.error('Ошибка при геокодировании:', err);
            });
        }
    };

    const handleMapClick = (event) => {
        try {
            const newCoordinates = event.get('coords');
            setCoordinates(newCoordinates);
            getaddressMap(newCoordinates);

            if (onMarkerSet) {
                const mapUrl = `https://yandex.ru/maps/?ll=${newCoordinates[1]}%2C${newCoordinates[0]}&z=16&pt=${newCoordinates[1]}%2C${newCoordinates[0]}`;
                onMarkerSet(mapUrl);
            }
        } catch (error) {
            console.error('Ошибка при клике на карту', error)
        }

    };

    return (
        <YMaps query={{ apikey: process.env.REACT_APP_YMAP_API_KEY }}>
            <Map
                defaultState={{ center: [55.75, 37.57], zoom: 10 }}
                width="100%"
                height="500px"
                onClick={handleMapClick}
                modules={["geocode"]}
            >
                {coordinates && (
                    <Placemark
                        geometry={coordinates}
                        properties={{
                            hintContent: 'Выбранная точка',
                            balloonContent: addressMap ? `Адрес: ${addressMap}` : `Координаты: ${coordinates[0]}, ${coordinates[1]}`,
                        }}
                    />
                )}
            </Map>
        </YMaps>
    );
};

export default YandexMap;
