import React, { useEffect, useState} from 'react';
import ApplicationForm from '../ApplicationForm/ApplicationForm';
import { useNavigate } from 'react-router-dom';
import {Button, Typography} from '@mui/material';
import Header from '../Header/Header';
import axios from 'axios';
import ButtonError from '../ButtonError/ButtonError';
import InstructionUploadButton from "../InstructionUploadButton/InstructionUploadButton";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const hostOld = process.env.REACT_APP_HOST_OLD;
const hostNow = window.location.host;
const newUrl = process.env.REACT_APP_URL_NEW;

const url = process.env.REACT_APP_SERVER_URL;
const path = process.env.REACT_APP_PATH;

const Application = () => {
    const [application, setApplication] = useState(true);
    const [alertText, setAlertText] = useState(false);
    let navigate = useNavigate();

    useEffect (() => {
        if(localStorage?.expire) {
            const nowDate = Math.floor(Date.now() / 1000);
            if(localStorage?.expire > nowDate){
                axios.post(`${url}/token/token`, localStorage, {
                    headers: {
                        'jwt-token': localStorage?.access,
                    }
                })
                    .then((response) => {
                        if (response.status === 210) {
                            navigate(`${path}/changePassword`, { replace: true });
                        } else if(response.status === 200) {
                            if(hostOld === hostNow) {
                                if(Number(localStorage?.status) === 0) {
                                    // запрос для получения строки
                                    if(!localStorage.code){
                                        axios.get(`${url}/accounts/code`, {
                                            headers: {
                                                'jwt-token': localStorage?.access,
                                            }
                                        })
                                            .then(response => {
                                                localStorage.code = response.data.url
                                                setAlertText(true)

                                            })
                                            .catch(error => {
                                                console.log(error)
                                            })
                                    } else {
                                        setAlertText(true)
                                    }
                                } else {
                                    setAlertText(true)
                                }
                            }
                            console.log('успешная авторизация')
                        } else {
                            navigate(`${path}/login`, { replace: true });
                            // console.log('токена нет на сервере')
                        }
                    })
                    .catch((error) => {
                        // console.log(error)
                    })
            } else {
                navigate(`${path}/login`, { replace: true });
                // console.log('истекло время для токена')
            }
        } else {
            navigate(`${path}/login`, { replace: true });
            // console.log('нет токена на клиенте')
        }
    }, [])

    const handleClose = () => {
        setAlertText(false);
    };

    return (
        <>
            <div style={{display: "flex", justifyContent: "flex-end", flexDirection: "column"}}>
                <Header setApplication={setApplication} application={application}/>
            </div>
            <div>
                {application ? (
                    <>
                        <ApplicationForm/>
                    </>
                ):(
                    <>
                        {/*<ListApplication/>*/}
                    </>
                )}
            </div>
        </>
    );
};

export default Application;