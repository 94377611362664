import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import printJS from 'print-js';
import { useRef, memo } from 'react';

const {getDescription} = require('../../utils/getDescription');

const DetailsModal = ({ isOpen, handleClose, item, formattedDate }) => {
  const modalRef = useRef(null); 
  const type = getDescription(item?.emergencyType);
  const Info = item?.location?.addInfo;
  const checkbox = item?.location?.crossroads ? true : false;
  const notes = item?.notes?.phoneFields;
  const cancel = item?.notes?.cancel?.reasonCancellation;

  const handlePrint = () => {
    if (modalRef.current) {
      printJS('DetailsModal', 'html')
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      ref={modalRef}
    >
      <Box 
      id='DetailsModal'
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 5,
      }}>
      <h3 id="modal-modal-title">{item?.regNumber ? 'Заявка №' + item?.regNumber : 'Нет номера зявки'} от {formattedDate}</h3>
      <div id="modal-modal-description">
      <div>
        <h4>Причина обращения</h4>
        <p>{type}</p>
        <p>- {item?.emergencyInfo?.text}</p>
      </div>
      <div>
      <h4>Адрес:</h4>
       <p>{ item?.location?.result ? item?.location?.result : '' }</p>
        {item?.notes?.mapLink ? (
            <Button onClick={() => window.open(item.notes.mapLink, '_blank')}>Ссылка на карту</Button>
        ) : (
            <></>
        )}
        <div>
          {checkbox && <>
          <input type="checkbox" disabled  checked={checkbox}/>
            <label>Пересечение</label>
            <p>
            Авария находится на пересечении: {item?.location?.crossroads}
            </p>
          </>}
          <h4>Дополнительная информация</h4>
          <p>{Info}</p>
        </div>
      </div>
      <div>
        <h3>Информация о заявителе</h3>
        <p>Подразделение: {item?.legalEntityType}</p>
        {notes && (
          notes.map(el => {
            return (<>
            <p> Телефон: {el.phone}; Примечание: {el.note};</p>
            </>)
          })
        )}
      </div>
      {cancel && (
        <>
        <h4>Причина отмены</h4>
        <p>{cancel}</p>
        </>
      )}
      <Button onClick={handlePrint}>Печать</Button>
    </div>
      <Button variant="contained" onClick={handleClose}>Закрыть</Button>
      </Box>
    </Modal>
  );
};

export default memo(DetailsModal);
// export default DetailsModal;
