import React, {useState} from 'react';
import {TextField, Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import './Login.css';
const url = process.env.REACT_APP_SERVER_URL;
const path = process.env.REACT_APP_PATH;

const Login = () => {

    const [Login, setLogin] = useState('');
    const [Password, setPassword] = useState('');
    const [err, setErr] = useState('');
    let navigate = useNavigate();

    const handleLogin = (event) => {
        event.preventDefault();
        axios.post(`${url}/login`, {Login, Password})
            .then((response) => {
                if (response.status === 200) {
                    localStorage.organizationId = response.data.user?.organizationId
                    localStorage.phone = response.data.user?.phone
                    localStorage.title = response.data.user.organization.title
                    localStorage.name = response.data.user.name
                    localStorage.access = response.data.token.access
                    localStorage.expire = response.data.token.expire
                    localStorage.userName = response.data.user.name
                    localStorage.id = response.data.user.id
                    localStorage.status = response.data.user.status
                    localStorage.organization = response.data.user?.organization?.title
                    localStorage.type = response.data.user?.organization?.type
                    console.log('path', path)
                    navigate(`${path}/application`, {replace: true});
                } else {
                    setErr(response.data.err)
                    setTimeout(()=> {
                        setErr('')
                    }, 5000)
                }
            })
            .catch((error) => {
                console.log(error)
                setErr('Произошла ошибка, попробуйте позже')
                setTimeout(()=> {
                    setErr('')
                }, 5000)
            })
    }

    return (
        <div className='form' style={{marginTop: '150px'}}>
            <h3>Кабинет для оформления заявок</h3>
            <form onSubmit={handleLogin} id='formLogin'>
                <TextField onChange={(e) => setLogin(e.target.value)} name="Login" value={Login}
                           sx={{margin: '20px'}}
                           label="Логин" className="outlined-size-normal" placeholder=""/>

                <TextField onChange={(e) => setPassword(e.target.value)} name="Password" value={Password}
                           sx={{margin: '20px'}}
                           label="Пароль" className="outlined-size-normal" placeholder="" type="Password"/>
                {err ? <div className='err' style={{color: 'red'}}>{err}</div> : null}
                {Login && Password ? (
                    <Button type="submit" sx={{margin: '20px'}}>
                        Войти
                    </Button>
                ) : (
                    null
                )}
            </form>
        </div>
    );
};

export default Login;