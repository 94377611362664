import React, { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import Checkbox from '@mui/material/Checkbox';
import './ApplicationForm.css';

const PermissionStreet = ({ Permission, setPermission, Address, City}) => {
  // const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [checkboxValue, setCheckboxValue] = useState(false);
  
  useEffect(() => {
    if (inputValue.length > 2) {
      fetchSuggestions(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue]);

  const fetchSuggestions = async (query) => {
    const token = process.env.REACT_APP_TOKEN;

    try {
      let sendQuery;
      if (query.toLowerCase().includes('москва') || query.toLowerCase().includes('московская')) {
        sendQuery = query
      } else {
        sendQuery = `${City} ${query}`
      }
      const response = await axios.post(process.env.REACT_APP_URL_DADATA, {
        query: sendQuery,
        //   locations: [
        //     {
        //          region: 'москва',
        //     },
        //     {
        //          region: 'московская',
        //     },
        // ],
        // locations_boost: [
        //     {
        //          kladr_id: '77',
        //     },
        //     {
        //          kladr_id: '50',
        //     }
        // ],

      }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
        }
      });
      setOptions(response.data.suggestions.map(el => el.value));
      return response.data.suggestions
    } catch (error) {
      console.error("error", error);
      return []
    }
  };

  return (
    <div style={{ display: "flex", width: '100vw', justifyContent: 'center' }}>

    {Address ? (
      <>
        <h3 style={{ width: '30vw'}}>Место аварии на пересечении улиц:        <Checkbox
            style={{ marginLeft: '10px' }}
            checked={checkboxValue}
            onChange={(event) => setCheckboxValue(event.target.checked)}
        /></h3>
      </>
    ):(
      <></>
    )}
    {checkboxValue && Address && (
      <Autocomplete
        id="google-map-demo"
        sx={{  width: '60vw', minWidth: '300px', marginLeft: '10px',  display: 'flex', alignItems: 'center'}}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.value)}
        filterOptions={(x) => x}
        options={options}
        disabled={!checkboxValue}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={Permission}
        noOptionsText="Введите больше 2х символов"
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setPermission(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          setPermission(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Введите адрес" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <>
            <li {...props} key={props.key}>
              <Grid container alignItems="center">
                <Grid item sx={{ width: '50vw', wordWrap: 'break-word' }}>
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    {props.key.substring(0, 0)}
                  </Box>
                  <Box component="span">
                    {props.key.substring(0, 10)}
                  </Box>
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    {props.key.substring(10)}
                  </Box>
                </Grid>
              </Grid>
            </li>
            </>
          );
        }}
      />
    )}
    </div>
  );
};

export default PermissionStreet;
